import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { IHomePage } from 'shared/models';
import { ContentService, MetaService } from 'src/app/shared/services';
import { BannerComponent } from '../shared/components/banner/banner.component';
import { ClientSpeaksComponent } from '../shared/components/client-speaks/client-speaks.component';
import { ContactFormComponent } from '../shared/components/contact-form/contact-form.component';
import { PortfolioComponent } from '../shared/components/portfolio/portfolio.component';
import { ServeComponent } from '../shared/components/serve/serve.component';
import { BlogsComponent } from './blogs/blogs.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { ServiceComponent } from './service/service.component';

@UntilDestroy({ arrayName: 'subscriptions' })
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    BannerComponent,
    ServiceComponent,
    ServeComponent,
    PortfolioComponent,
    GetInTouchComponent,
    ClientSpeaksComponent,
    BlogsComponent,
    ContactFormComponent,
  ],
})
export class HomeComponent {
  page = signal<IHomePage>(null);
  getInTouch = signal<HTMLElement>(null);
  clientServedSectionDetail = {
    sectionCss: 'serve bg-light',
  };
  private subscriptions = [];
  constructor(metaService: MetaService, contentService: ContentService) {
    this.subscriptions.push(
      contentService
        .getHome()
        .pipe(
          map((page) => {
            this.page.set(page);
            metaService.updateMetaTags(page.seo);
          }),
        )
        .subscribe(),
    );
  }
}
