<ng-container>
  <app-banner component="home" [bannerSection]="page()?.bannerSection" [scrollToButton]="getInTouch()"></app-banner>
  @defer (on idle;on viewport; hydrate on idle;prefetch on idle) {
  <app-service [servicesSection]="page()?.servicesSection"></app-service>
  } @placeholder {
  <div class="component-placeholder">Preparing content for service ...</div>
  }

  @defer (on idle;on viewport; hydrate never;prefetch on idle) {
  <app-serve [clientPersonasSection]="page()?.clientPersonasSection" sectionStyle="serve bg-light"></app-serve>
  }@placeholder {
  <div class="component-placeholder">Preparing content for app serve ...</div>

  }

  @defer (on idle;on viewport; hydrate on viewport;prefetch on idle) {
  <app-portfolio [portfoliosSection]="page()?.portfoliosSection"></app-portfolio>
  }@placeholder {
  <div class="component-placeholder">Preparing content for portfolio ...</div>

  }

  @defer (on idle;on viewport; hydrate on viewport;prefetch on idle) {
  <app-get-in-touch [callToAction]="page()?.callToAction" [scrollToButton]="getInTouch()"></app-get-in-touch>
  }@placeholder {
  <div class="component-placeholder">Preparing content for get-in-touch ...</div>

  }

  @defer (on idle;on viewport; hydrate on viewport;prefetch on idle) {
  <app-client-speaks [testimonialsSection]="page()?.testimonialsSection"></app-client-speaks>
  }@placeholder {
  <div class="component-placeholder">Preparing content for client-speaks ...</div>

  }

  @defer (on idle;on viewport; hydrate on viewport;prefetch on idle) {
  <app-blogs [blogsSection]="page()?.blogsSection"></app-blogs>
  }@placeholder {
  <div class="component-placeholder">Preparing content for blogs ...</div>

  }


  <app-contact-form [contactSection]="page()?.contactSection"
    (getInTouchRef)="getInTouch.set($event)"></app-contact-form>


</ng-container>